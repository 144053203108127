import React, { useState } from "react"
import Layout from '../components/Layout';
import Seo from '../components/Seo';

import { graphql, useStaticQuery, Link } from 'gatsby'
import { getImage, StaticImage, GatsbyImage, withArtDirection } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import chartIcon from '../images/homepage/icon-chart.svg';
import hourglassIcon from '../images/homepage/icon-hourglass.svg';
import connectionsIcon from '../images/homepage/icon-connections.svg';
import quotientIcon from '../images/logos/quotient-icon.png';
import { CheckCircle } from 'react-feather';
import HomeAccordion from '../components/HomeAccordion';

const Home = () => {

    const [showDemo, setShowDemo] = useState();

    const [activeSlide, setActiveSlide] = useState(0);

    const data = useStaticQuery(
        graphql`
          query {
            placeholderImage: file(relativePath: { eq: "homepage/home-top-bg2.jpg" }) {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            },
            dashboardDesktop: file(relativePath: { eq: "homepage/stack-desktop-0.png" }) {
                childImageSharp {
                  gatsbyImageData(
                    width: 782,
                    placeholder: BLURRED,
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
            },
            dashboardMobile: file(relativePath: { eq: "homepage/stack-mobile-0.png" }) {
                childImageSharp {
                  gatsbyImageData(
                    width: 616,
                    placeholder: BLURRED,
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
            },
            chartDesktop: file(relativePath: { eq: "homepage/stack-desktop-1.png" }) {
                childImageSharp {
                  gatsbyImageData(
                    width: 1000,
                    placeholder: BLURRED,
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
            },
            chartMobile: file(relativePath: { eq: "homepage/stack-mobile-1.png" }) {
                childImageSharp {
                  gatsbyImageData(
                    width: 462,
                    placeholder: BLURRED,
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
            },
          }
        `
    )
    const image = getImage(data && data.placeholderImage);
    const bgImage = convertToBgImage(image);

    const chartImages = withArtDirection(getImage(data.chartDesktop), [
        {
            media: "(max-width: 768px)",
            image: getImage(data.chartMobile),
        },
    ]);

    const dashboardImages = withArtDirection(getImage(data.dashboardDesktop), [
        {
            media: "(max-width: 768px)",
            image: getImage(data.dashboardMobile),
        },
    ]);

    return (
        <Layout showDemo={showDemo}>
            <Seo title='Home' />
            <BackgroundImage
                Tag="section"
                {...bgImage}
                preserveStackingContext
            >
                <div className="home-intro">
                    <div className="container">
                        <div className="home-intro__heading">
                            <div className="home-intro__heading__icon">
                                <StaticImage quality='100' placeholder='none' className='home-intro__heading__icon__img' src="../images/homepage/logos/quotient-icon-rev.svg" alt='' />
                            </div>
                            <div className="home-intro__heading__content">
                                <h1 className='uppercase text-white'><span className='text-pink'>The</span> Data & Analytics Solution For Investment Professionals</h1>
                                <div className="home-intro__heading__sub">
                                    <CheckCircle className='mr-md-2 mb-2 mb-md-0 text-pink' /> <h5 className='uppercase text-white mb-0'>Designed By Investment Professionals</h5>
                                </div>
                                <Link to="/solutions" className="btn btn-dark-opacity btn-wide mb-2 mb-sm-0">Learn More</Link>
                            </div>
                        </div>
                        <div className="home-intro__brand">
                            <StaticImage quality='100' placeholder='none' className='home-intro__brand__img' src="../images/homepage/logos/sfs-primary-mono-rev.svg" alt='Sfs logo' />
                        </div>
                    </div>
                </div>
            </BackgroundImage>

            <section className="home-intro-stack">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <ul className='intro-list'>
                                <li>
                                    <div className="heading">
                                        <img src={quotientIcon} alt='icon' />
                                        <h3 className='uppercase mb-0'>Enterprise solution</h3>
                                    </div>
                                    <div className="content">
                                        <p>Quotient&trade;, Scientific Financial Systems' flagship product, provides an enterprise solution for investment managers that provides a data driven view of investment opportunities and strategies, along with more control and greater flexibility.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="heading">
                                        <img src={quotientIcon} alt='icon' />
                                        <h3 className='uppercase mb-0'>Rapid Analysis</h3>
                                    </div>
                                    <div className="content">
                                        <p>Quotient&trade; enables financial institutions to analyze rapidly large amounts of data and provide data-driven results and recommendations.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="heading">
                                        <img src={quotientIcon} alt='icon' />
                                        <h3 className='uppercase mb-0'>Trusted data</h3>
                                    </div>
                                    <div className="content">
                                        <p>Quotient&trade; partners with and integrates with some of the largest global financial data providers and provides an advanced analytical engine for data manipulation, factor building, back-testing and portfolio construction.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-5 d-flex align-items-center justify-content-center order-first order-md-0">
                            <div className="home-stack-images mb-4 mb-md-0">
                                <GatsbyImage objectFit="contain" quality='100' alt='dashboardImages' image={dashboardImages} />
                                <GatsbyImage objectFit="contain" quality='100' alt='chartImages' className='img-alt' image={chartImages} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-muted py-4">
                <div className="container">
                    <h4 className='heading-underline heading-underline-large uppercase'>Implementing Scientific Financial's Quotient&trade; will provide your firm with significant economic benefits:</h4>
                    <div className="acc">
                        <HomeAccordion
                            open={0 === activeSlide}
                            handleClick={() => setActiveSlide(0)}
                            title="Improve Research team effectiveness"
                            content="We all know that the best research teams have high levels of collaboration to help reduce risk. Quotient&trade; was designed to allow your team to share the same platform to promote transparency and standardization of formulas and approaches."
                            list="<ul>
                                <li>Users share a common research platform</li>
                                <li>Promotes collaboration</li>
                                <li>Reduces single person risk</li>
                                <li>Makes transparency and standardization of formulas easy</li>
                            </ul>"
                            icon={chartIcon}
                        />

                        <HomeAccordion
                            open={1 === activeSlide}
                            handleClick={() => setActiveSlide(1)}
                            title="More time on research and developing quantitative models"
                            content="Spend less time developing your research architecture and more time on  generating research output and actionable quantitative models. Use a solution built by practitioners with a track record of success."
                            list="<ul>
                                <li>End-to-end research workflow</li>
                                <li>Define derived data items</li>
                                <li>Build screens and multi-level models</li>
                                <li>Extensively Backtest and Forecast using advanced machine learning techniques to enhance alpha</li>
                                <li>Leverage factor examples and tutorials to get users working quickly</li>
                            </ul>"
                            icon={hourglassIcon}
                        />

                        <HomeAccordion
                            open={2 === activeSlide}
                            handleClick={() => setActiveSlide(2)}
                            title="Effectively integrate vendor and internal data sources"
                            content="Newly acquired data sources can be quickly integrated and ready for use. Vendor and platform agnostic data sourcing allows seamless integration into models."
                            list="<ul>
                                <li>Supports SQL, cloud sources such as Snowflake, CSV files, Excel and more</li>
                                <li>Data standardized for frequency, currency, and corporate actions</li>
                                <li>Data presented in an organized and self-documented manner</li>
                                <li>Supports Point-In-Time data sources for improved predictive power</li>
                            </ul>"
                            icon={connectionsIcon}
                        />
                    </div>
                    <button className='btn btn-primary btn-wide mb-2 mb-sm-0 homeCTA' onClick={setShowDemo}>Request demo of Quotient&trade;</button>
                </div>
            </section>

        </Layout>
    )
}

export default Home;