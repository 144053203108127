import React from "react";
import { ChevronRight } from 'react-feather';
import cx from 'classnames';

const HomeAccordion = ({ content, list, title, icon, handleClick, open }) => {

    return (
        <div className={cx('acc-item', {'acc-open': open})}>
            <button onClick={() => handleClick()} className="acc-heading">
                <div className="acc-title">
                    <ChevronRight className='acc-toggle' />
                    <h4 className='uppercase mb-0'>{title}</h4>
                </div>
                <div className="acc-icon-holder">
                    <div className="acc-icon">
                        <img alt='icon' src={icon} />
                    </div>
                </div>
            </button>
            <div className="acc-content">
                <div className="acc-extras">
                    {content}
                </div>
                <div className="acc-list" dangerouslySetInnerHTML={{ __html: list }}></div>
            </div>
        </div>
    );
}

export default HomeAccordion;
